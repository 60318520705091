.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: #1c273c;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    border: var(--bs-pagination-border-width) solid rgb(255, 255, 255);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.disabled > .page-link, .page-link.disabled {
    color: #1c273c;
    pointer-events: none;
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
}

.pagination {
    --bs-pagination-padding-x: 0.45rem;
    --bs-pagination-padding-y: 0.1rem;
    --bs-pagination-border-color: rgb(255, 255, 255);
    --bs-pagination-font-size: 0.8rem;
    --bs-pagination-color: rgb(255, 255, 255);
    --bs-pagination-bg: rgb(255, 255, 255);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: rgb(255, 255, 255);
    --bs-pagination-active-bg: #1c273c;
    --bs-pagination-active-border-color: #1c273c;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: flex;
    padding-left: 0;
    margin-top: 10px;
    padding-bottom: 10px;
    list-style: none;
}